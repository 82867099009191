<div
    id="fi-element"
    class="flex flex-row items-center"
    [ngClass]="{ 'file-item': filePreview }"
    matRipple
    (click)="previewFile()"
>
    <i class="fa-duotone fg-gray" [ngClass]="getFileIcon(file?.type)" aria-hidden="true"></i>
    <span id="fi-file-name" class="px-2 py-2 font-bold">{{ getFileName(file?.name) }}</span>
    <span id="fi-file-size" class="pr-2">{{ formatBytes(file?.size) }}</span>
    <button
        *ngIf="canRemoveFile"
        id="fi-remove-file"
        mat-icon-button
        type="button"
        aria-label="Datei entfernen"
        matTooltip="Datei entfernen"
        (click)="removeFile($event)"
    >
        <mat-icon fontSet="fa-solid" fontIcon="fa-xmark" class="text-gray-500"></mat-icon>
    </button>
</div>
