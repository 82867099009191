// @ts-strict-ignore
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';

@Component({
    selector: 'k5-file-item',
    templateUrl: './file-item.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./file-item.component.scss']
})
export class FileItemComponent implements OnDestroy {
    @Input()
    file: File;

    @Input()
    filePreview: boolean = false;

    @Input()
    canRemoveFile: boolean = true;

    @Output()
    removeClick: EventEmitter<Event> = new EventEmitter<Event>();

    private blobLink: string = '';

    /**
     * Gibt alle Ressourcen frei
     */
    ngOnDestroy(): void {
        if (this.blobLink) {
            window.URL.revokeObjectURL(this.blobLink);
        }
    }

    /**
     * Gibt den Dateinamen zurück
     * @returns Dateinamen
     */
    getFileName(fileName: string): string {
        return fileName ?? 'Keine Datei vorhanden';
    }

    /**
     * Ermittelt das richtige FA-Icon für den Dateityp
     * @param type Dateityp
     */
    getFileIcon(type: string): string {
        if (!type) {
            return 'fa-file';
        }

        if (type.startsWith('image/')) {
            return 'fa-file-image';
        }

        switch (type) {
            case 'application/pdf':
                return 'fa-file-pdf';
            case 'text/csv':
                return 'fa-file-csv';
            case 'application/zip':
                return 'fa-file-zipper';
            default:
                return 'fa-file';
        }
    }

    /**
     * Bereitet die Dateigröße für die Ausgabe auf
     * @param bytes (File size in bytes)
     * @param decimals (Decimals point)
     */
    formatBytes(bytes: number, decimals: number = 2): string {
        if (!bytes) {
            return '0 Bytes';
        }
        const k = 1024;
        const dm = decimals <= 0 ? 0 : decimals || 2;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
    }

    /**
     * Feuert das removeClickEvent
     * @param event Click-Event
     */
    removeFile(event: Event): void {
        event.stopPropagation();
        this.removeClick.emit(event);
    }

    /**
     * Triggert die Vorschau der Datei in einem eigenen Browser-Tab
     */
    previewFile(): void {
        if (this.filePreview) {
            if (!this.blobLink) {
                this.blobLink = window.URL.createObjectURL(this.file);
            }
            window.open(this.blobLink);
        }
    }
}
