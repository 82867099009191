<!-- Auflistung der Dokumente -->
<div class="divide-y divide-gray-300 pb-2 pt-2">
    <div *ngFor="let file of files; let i = index">
        <k5-file-item [file]="file" [filePreview]="true" (removeClick)="removeFile(i)"> </k5-file-item>
    </div>
</div>
<!-- Drag and Drop Bereich -->
<div
    id="dndf-drop-section"
    k5DragAndDrop
    class="rounded-lg border-2 border-dashed border-gray-400 p-4"
    (fileDropped)="onFileDropped($event)"
>
    <input
        class="hidden"
        type="file"
        #fileDropRef
        id="fileDropRef"
        multiple
        [accept]="getAcceptTypes()"
        (click)="inputClicked($event)"
        (change)="fileBrowseHandler($event.target.files)"
        data-cy="upload-input"
    />
    <div class="pointer-events-none flex w-full flex-col items-center">
        <i id="upload-icon" class="fa-duotone fa-cloud-arrow-up fa-3x fa-fw text-gray-500" aria-hidden="true"></i>
        <span class="pt-1 font-bold">{{ text }}</span>
        <span class="pt-1">{{ description }}</span>
        <div class="flex flex-row items-center pt-1" *ngIf="supportedFileFormats">
            Unterstützte Formate:
            <span class="pl-1" *ngFor="let fileType of supportedFileFormats">{{ fileType.endings.join(" ") }}</span>
        </div>
        <label class="fg-primary pointer-events-auto pt-1 hover:cursor-pointer hover:opacity-80" for="fileDropRef"
            >Datei auswählen</label
        >
    </div>
</div>
