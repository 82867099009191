// @ts-strict-ignore
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AlertService } from '@core/services/alert.service';
import { GlobalEditService } from '@core/services/global-edit.service';
import { EditSectionConstants } from '@shared/constants/shared-constants';
import { FileFormat } from '../file-format';

export interface UploadFilesDialogData {
    title: string;
    fileFormats: FileFormat[];
    formDataName: string;
}

/**
 * Dialog für das Upload von Dateien
 */
@Component({
    selector: 'k5-upload-files-dialog',
    templateUrl: './upload-files-dialog.component.html'
})
export class UploadFilesDialogComponent implements OnDestroy {
    title: string = 'Datei hochladen';
    filename = 'Keine Datei ausgewählt';
    fileFormats: FileFormat[] = [];
    isFileSelected = false;
    progress = 0;
    loading = false;

    formdata = new FormData();

    // Event für das File-Upload
    uploadClick: EventEmitter<FormData> = new EventEmitter<FormData>();

    constructor(
        private readonly alertService: AlertService,
        private readonly globalEditService: GlobalEditService,
        @Inject(MAT_DIALOG_DATA) public data: UploadFilesDialogData,
        public dialogRef: MatDialogRef<UploadFilesDialogComponent>
    ) {
        this.globalEditService.switchToEditMode(EditSectionConstants.SHARED_FILE_UPLOAD);
        this.title = data?.title;
        this.fileFormats = data?.fileFormats;
    }

    ngOnDestroy(): void {
        this.globalEditService.switchToReadMode();
    }

    /**
     * Uploads file to REST-Service which triggers an Azure function
     */
    uploadFile(): void {
        // If upload is currently in progress do not allow further initiations of upload
        if (this.loading) {
            return;
        }

        if (this.isFileSelected) {
            this.progress = 0;
            this.loading = true;
            this.uploadClick.emit(this.formdata);
        } else {
            this.alertService.info('Bitte wählen Sie zuerst eine Datei aus');
        }
    }

    /**
     * Checks if files are selected. If files are selected takes the first one and reads the content to
     * upload it to the WV-Service.
     * @param files selected files from file input dialog
     */
    onFileSelected(files: File[]) {
        this.formdata = new FormData();
        if (files && files.length > 0) {
            const file = files[0];

            this.filename = file.name;
            this.isFileSelected = true;

            // Datei an das FormData Objekt hängen
            // Inhalt von formDataName darf nicht geändert werden, da diese der Backend-Service erwartet
            this.formdata.append(this.data.formDataName, file, file.name);
        }
    }

    /**
     * Behandelt HttpEvents während des Dateiuploads und aktualisiert die
     * Fortschrittsanzeige.
     * @param event HttpEvent des Upload-Progresses
     */
    handleHttpEvent(event: HttpEvent<unknown>): void {
        switch (event.type) {
            // Request has been made
            case HttpEventType.Sent:
                break;
            // Response header has been received
            case HttpEventType.ResponseHeader:
                break;
            // Upload progress report event
            case HttpEventType.UploadProgress:
                this.loading = true;
                this.progress = Math.round((event.loaded / event.total) * 100);
                break;
            // Upload completed
            case HttpEventType.Response:
                this.loading = false;
                this.dialogRef.close(true);
                break;
        }
    }

    /**
     * Baut den accept-String für die erlaubten Typen zusammen
     */
    getAcceptTypes(): string {
        if (!this.fileFormats) {
            return '';
        }
        return this.fileFormats.map((x) => x.mimeType).join(',');
    }
}
