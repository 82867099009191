import { Pipe, PipeTransform } from '@angular/core';
import { JaNeinAlleConstants } from '@shared/constants/shared-constants';

@Pipe({
    name: 'showBoolean'
})
export class ShowBooleanPipe implements PipeTransform {
    /**
     * Übersetzt Boolean-Werte in eine textuelle Form.
     * @param value boolean
     */
    transform(value: boolean): string {
        switch (value) {
            case true:
                return JaNeinAlleConstants.JA;
            case false:
                return JaNeinAlleConstants.NEIN;
        }
    }
}
