import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, EventEmitter, Output, inject, signal } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from '@core/services/alert.service';
import { ConfigAssetLoaderService } from '@core/services/config-asset-loader.service';
import { MandantenService } from '@core/services/mandanten.service';
import { UserInformationService } from '@core/services/user-information.service';
import {
    K5NextNotification,
    K5NextNotificationAction,
    K5NextNotificationBenutzerUrlKey,
    K5NextNotificationInfo,
    K5NextNotificationTemplateType,
    K5NextNotificationTypeEnum,
    NotificationPanelComponent
} from '@k5next/ui-components';
import { NotificationService } from '@shared/services/notification.service';

@Component({
    selector: 'k5-notifications',
    standalone: true,
    imports: [CommonModule, NotificationPanelComponent],
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.components.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationsComponent {
    /**
     * Inputs & Outputs
     */
    @Output()
    closePanel: EventEmitter<void> = new EventEmitter();

    /**
     * Variablen
     */
    dataSourceBaseUrl = signal('');
    componentTemplate = K5NextNotificationTemplateType.K5NextFachapplikation;
    // Kennzeichnet welche API soll benutzt werden
    benutzerTypeUrlKey = K5NextNotificationBenutzerUrlKey.FachlicheBenutzerUrlKey;

    // Services injekten
    userInformationService = inject(UserInformationService);
    alertService = inject(AlertService);
    notificationService = inject(NotificationService);
    configAssetLoaderService = inject(ConfigAssetLoaderService);
    router = inject(Router);
    mandantenService = inject(MandantenService);

    constructor() {
        // Abfrage Base Url von NotificationService laden
        this.dataSourceBaseUrl.set(
            this.configAssetLoaderService.getConfig().k5Notification.notificationServiceUiApiBaseUrl
        );
    }

    /**
     * Handelt den Error Event von Notification Panel.
     * @param error Übermittelte HttpError.
     */
    handleError(error: HttpErrorResponse): void {
        this.alertService.errorResponse(error, 'Fehler während der Verarbeitung der Benachrichtigungen');
    }

    /**
     * Handelt den Click Event auf eine Notification.
     * @param notification auf die geklickt wurde.
     */
    handleClick(notification: K5NextNotification): void {
        if (notification.type === K5NextNotificationTypeEnum.K5NextReferenz) {
            this.handleVerweisTypeNotification(notification);
        } else {
            this.handleLinkKlick(notification);
        }
        // Badge Counter aktualisieren
        this.setBadgeCounter(this.notificationService.numberOfUngeleseneNotifications() - 1);
    }

    /**
     * Setzt den Badge Counter.
     * @param value der  Counter.
     */
    setBadgeCounter(value: number): void {
        this.notificationService.setNotificationAmount(value);
    }

    /**
     * Handelt den Klick auf einen Link der Info Notification.
     * Wenn es sich um einen Hilfe Link handelt, wird der K5Next Hilfe Access Token abgefragt und die Seite in einem neuen Tab geöffnet.
     * Ansonsten wird der Link in einem neuen Tab geöffnet.
     * @param notification - die Notification.
     */
    handleLinkKlick(notification: K5NextNotification): void {
        if (notification?.link?.url) {
            if (this.isHilfeLink(notification?.link.url)) {
                // k5|Next Hilfe Access Token abfragen
                this.mandantenService.getK5NextHilfeToken().subscribe({
                    next: (token: string) => {
                        // Die Seite öffnen
                        window
                            .open(
                                `${this.configAssetLoaderService.getConfig().k5NextHilfe.baseUrl}/?t=${token}`,
                                '_blank'
                            )
                            ?.focus();
                    },
                    error: (error: HttpErrorResponse) => {
                        this.alertService.errorResponse(error, 'Fehler bei der Abfrage des K5Next Hilfe Access Tokens');
                    }
                });
                // Schritte 2:  Link öffnen in neuen Tab.
            } else {
                window.open(notification.link.url, '_blank')?.focus();
            }
        }
    }

    /**
     * Handelt den Klick auf eine Verweis Notification.
     * @param notification - die Notification.
     */
    handleVerweisTypeNotification(notification: K5NextNotification): void {
        const url = this.notificationService.getVerweisUrlNeu(notification);
        if (url) {
            this.router.navigateByUrl(url);
        }
        this.closePanel.emit();
    }

    /**
     * Setzt die Anzahl der ungelesene Benachrichtigungen für die UI Darstellung.
     * @param numberOfUnreadNotifications die Anzahl der ungelesene Beanchrichtigungen.
     */
    handleNumberOfUnreadNotifications(numberOfUnreadNotifications: number): void {
        this.notificationService.setNotificationAmount(numberOfUnreadNotifications);
    }

    /**
     * Handelt das Notification Info Event.
     * @param event, der übermittelte Info Event.
     */
    handleNotificationInfoEvent(event: K5NextNotificationInfo): void {
        switch (event.action) {
            case K5NextNotificationAction.MarkAllAsRead:
                this.notificationService.setNotificationAmount(0);
                break;
            case K5NextNotificationAction.Click:
                if (event.notification) {
                    this.handleClick(event.notification);
                }
                break;
            default:
                if (event.numberOfUnreadNotifications !== null && event.numberOfUnreadNotifications !== undefined) {
                    this.notificationService.setNotificationAmount(event.numberOfUnreadNotifications);
                }
                break;
        }
    }

    /**
     * Stellt fest, ob der Link ein Hilfe-Link ist.
     * @param link der Link.
     * @returns true oder false.
     */
    isHilfeLink(link: string): boolean {
        return link.includes('hilfe.k5next.at');
    }
}
