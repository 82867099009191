import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DialogActionsComponent, DialogContentComponent, DialogHeadingComponent } from '@k5next/ui-components';
import { DragAndDropFilesDirective } from './directives/drag-and-drop-files.directive';
import { DragAndDropFilesComponent } from './drag-and-drop-files/drag-and-drop-files.component';
import { FileItemComponent } from './file-item/file-item.component';
import { UploadFilesDialogComponent } from './upload-files-dialog/upload-files-dialog.component';

@NgModule({
    imports: [
        CommonModule,
        MatButtonModule,
        MatProgressBarModule,
        MatDialogModule,
        MatTooltipModule,
        MatRippleModule,
        MatIconModule,
        DialogHeadingComponent,
        DialogContentComponent,
        DialogActionsComponent
    ],
    declarations: [DragAndDropFilesDirective, DragAndDropFilesComponent, UploadFilesDialogComponent, FileItemComponent],
    exports: [DragAndDropFilesDirective, DragAndDropFilesComponent, UploadFilesDialogComponent, FileItemComponent]
})
export class FileUploadModule {}
