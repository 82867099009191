// @ts-strict-ignore
import { OverlayContainer } from '@angular/cdk/overlay';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { UnsavedChangesDialogComponent } from '@core/components/unsaved-changes-dialog/unsaved-changes-dialog.component';
import { UiConstants } from '@core/constants/ui-constants';
import { PathConstants, RouterLinkConstants } from '@core/constants/url-constants';
import { LoadingInfo } from '@core/models/loading-info';
import { SidemenuPathEnum } from '@core/models/sidemenuPathEnum';
import { AuthService } from '@core/services/auth.service';
import { ConfigAssetLoaderService } from '@core/services/config-asset-loader.service';
import { GlobalEditService } from '@core/services/global-edit.service';
import { GlobalLoadingService } from '@core/services/global-loading.service';
import { MandantenService } from '@core/services/mandanten.service';
import { NavigationService } from '@core/services/navigation.service';
import { RightSidenavigationService } from '@core/services/right-sidenavigation.service';
import { ThemeService } from '@core/services/theme.service';
import { UserInformationService } from '@core/services/user-information.service';
import { K5NextNotificationResponse } from '@k5next/ui-components/lib/notifications/models/k5-next-notification-response';
import { KontaktmanagementDetailsuche } from '@shared/constants/shared-constants';
import { MandantClient } from '@shared/models/mandantenClient';
import { AdressenService } from '@shared/services/adressen.service';
import { NotificationService } from '@shared/services/notification.service';
import { SignalRService } from '@shared/services/signal-r.service';
import {
    Observable,
    Subscription,
    catchError,
    filter,
    forkJoin,
    interval,
    mergeMap,
    of,
    startWith,
    switchMap
} from 'rxjs';
import { environment } from 'src/environments/environment';
import { chevronAnimation } from './app.component.animations';
import { UserService } from './buergerservice/user/services/user.service';

/**
 * Wurzelkomponente der Anwendung
 */
@Component({
    selector: 'k5-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    animations: chevronAnimation
})
export class AppComponent implements OnInit, OnDestroy {
    subscription: Subscription = new Subscription();
    chevronState: 'initial' | 'final' = 'initial';

    /**
     * Wird benötigt um die Klasse des Material themes für die Overlays zu setzen
     */
    lastMaterialTheme = '';

    specifyDetailSearch: SidemenuPathEnum = '';

    // Benutzereinstellungen geladen
    userInformationLoaded$ = this.userInformationService.userInformationLoaded$;
    // Anzeige einer Fehlermeldung beim Laden der Benutzereinstellungen
    errorLoadingUserInformation: boolean = false;
    errorMessage: string = '';
    allowedToAccess: boolean = false;

    // Alias für die Verwendung der Konstanten im HTML Template
    public PATH_CONSTANTS = PathConstants;

    /**
     * ThemeService wird hier benötigt, da so bereits beim Anwendungsstart die Farbe gesetzt wird und
     * der Service von der DI initialisiert wird.
     */
    constructor(
        public navigationService: NavigationService,
        public rightSidenavigationService: RightSidenavigationService,
        private readonly userInformationService: UserInformationService,
        private readonly userService: UserService,
        public themeService: ThemeService,
        private readonly overlayContainer: OverlayContainer,
        public authService: AuthService,
        private readonly router: Router,
        private readonly loadingService: GlobalLoadingService,
        private readonly dialog: MatDialog,
        private readonly notificationService: NotificationService,
        private readonly globalEditService: GlobalEditService,
        private readonly mandantenService: MandantenService,
        private readonly signalRService: SignalRService,
        private readonly configAssetLoader: ConfigAssetLoaderService,
        private readonly adressenService: AdressenService
    ) {}

    /**
     * Returniert das Lade-Observable
     */
    get loading$(): Observable<LoadingInfo> {
        return this.loadingService.loading$;
    }

    /**
     * Returniert das Login-Fehler-Observable
     */
    get hasAuthenicationFailed$(): Observable<boolean> {
        return this.authService.hasAuthenticationFailed$;
    }

    /**
     * Returniert das Logout-Observable
     */
    get loggingOut$(): Observable<boolean> {
        return this.authService.loggingOut$;
    }

    ngOnInit(): void {
        this.subscription.add(
            this.navigationService.sidenavExpandable$.subscribe((expandable: boolean) => {
                this.chevronState = expandable ? 'final' : 'initial';
            })
        );

        // Update Material Theme nachdem die UI Daten geladen oder verändert wurden
        this.subscription.add(
            this.userInformationService.uiInitState$
                .pipe(
                    filter((state) => state !== null),
                    switchMap((): Observable<MandantClient.UIBenutzerEinstellungen> => {
                        // Frag danach die Benutzereinstellungen ab, da die Benutzereinstellungen vom init Objekt nicht immer akutell sind
                        return this.userService.getUserSettings(this.userInformationService.getBenutzerId());
                    })
                )
                .subscribe({
                    next: (data: MandantClient.UIBenutzerEinstellungen) => {
                        this.themeService.setTheme(
                            this.themeService.findThemeByLabelAndDarkModeFlag(data.data.farbschema, data.data.darkMode)
                        );
                    }
                })
        );

        /**
         * Update Material theme for overlays
         */
        this.subscription.add(
            this.themeService.theme$.subscribe((data) => {
                if (this.lastMaterialTheme) {
                    this.overlayContainer.getContainerElement().classList.remove(this.lastMaterialTheme);
                }
                this.overlayContainer.getContainerElement().classList.add(data.materialClassName);
                this.lastMaterialTheme = data.materialClassName;
            })
        );

        /**
         * Achtet auf neue Routeraktivitäten
         */
        this.subscription.add(
            this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
                this.checkForSpecificCurrentUrl();
            })
        );

        /**
         * Überprüft ob der Benutzer eingeloggt ist um die Benutzereinstellungen zu laden
         */
        this.subscription.add(
            this.authService.isAuthenticated$.subscribe((authenticated: boolean) => {
                if (authenticated) {
                    // Löschen des Session-Storage-Eintrags für die Wiederholung der Anmeldung
                    sessionStorage.removeItem('retry_login');
                    this.loadUserInformationAndTriggerAbgleiche();
                    this.getNotifications();

                    // Aufbauen der SignalR-Verbindung für die Wahlvorbereitung
                    this.signalRService.startConnection();
                }
            })
        );
    }

    /**
     * Fragt periodisch nach neuen Benachrichtigungen
     * Aktuell alle 3h, um Last zu vermindern
     * Benachrichtigungen werden duch Klick auf NavItem sowieso neu geladen
     */
    getNotifications(): void {
        interval(environment.notificationRefreshTime)
            .pipe(
                startWith(0),
                mergeMap(() => {
                    return this.notificationService.getNotifications(false).pipe(
                        catchError(() => {
                            return of(null);
                        })
                    );
                })
            )
            .subscribe({
                next: (response: K5NextNotificationResponse) => {
                    if (response) {
                        // Die Aufarbeitung von neuen Benachrichtigungen wird durch die erste Initialisierungsdurchgang nicht durchgeführt
                        if (!this.notificationService.isInitialLoadingDone()) {
                            this.notificationService.setNotificationAmount(response.anzahlUngelesene);
                            this.notificationService.isInitialLoadingDone.set(true);
                        } else {
                            this.notificationService.setNewNotificationInfo(response);
                        }
                    }
                }
            });
    }

    /**
     * Lädt die Benutzerinformation und triggert den ZMR- sowie den AGWR-Abgleich
     * Weiters wird die ZeWaeR-Korrektur gestartet, welche für alle Mandantenwahlen fehlende
     * Übermittlungen hinzufügt.
     */
    loadUserInformationAndTriggerAbgleiche(): void {
        this.subscription.add(
            this.userInformationService.refreshUserAndUiInformation().subscribe({
                next: () => {
                    this.triggerAbgleiche();
                    const currRoute = this.router.url;
                    this.errorLoadingUserInformation = false;

                    if (currRoute.includes(`${PathConstants.INITIALIZATION}`)) {
                        this.router.navigate([RouterLinkConstants.HOME]);
                    }
                },
                error: (error: HttpErrorResponse) => {
                    this.errorLoadingUserInformation = true;
                    if (!error?.error?.detail && error?.status === 404) {
                        this.errorMessage = 'Die angeforderte Ressource wurde nicht gefunden.';
                    }
                    if (error?.error?.detail) {
                        this.errorMessage = error?.error?.detail;
                    }
                    // Wenn kein Error Detail vorhanden ist, wird der title verwendet
                    else if (error?.error?.title) {
                        this.errorMessage = error?.error?.title;
                    }

                    // Prüft ob der Benutzer auf das UI darf
                    this.allowedToAccess =
                        error?.error?.detailValues?.additionalInformation !== UiConstants.UI_ACCESS_DENIED_ERROR_ID;
                }
            })
        );
    }

    /**
     * Paralleler Aufruf der Abgleiche im Hintergrund nach der Benutzerinitialisierung
     * Fehler werden ignoriert
     */
    triggerAbgleiche(): void {
        this.subscription.add(
            forkJoin({
                zmrAbgleich: this.mandantenService.triggerZMRAbgleich().pipe(
                    catchError(() => {
                        return of(null);
                    })
                ),
                agwrAbgleich: this.adressenService.triggerAGWRAbgleich().pipe(
                    catchError(() => {
                        return of(null);
                    })
                )
                // Aufgrund PBI 16493 wird die automatische Korrektur deaktiviert. Der Code soll aber vorerst noch verfügbar bleiben
                // zewaerUebermittlungen: this.wvzService.correctMissingZewaerUebermittlungen().pipe(
                //     catchError(() => {
                //         return of(null);
                //     })
                // )
            }).subscribe()
        );
    }

    /**
     * Erneutes ausführen von fehlerhaft beendeten Initialisierungsroutinen
     * Oder navigiert zur Admin Oberfläche, wenn der Benutzer nicht auf die Oberfläche zugreifen darf
     */
    retryOrNavigateToAdmin(): void {
        if (this.allowedToAccess) {
            // Lädt User Infos neu
            this.loadUserInformationAndTriggerAbgleiche();
        } else {
            // Navigiert zur Admin Oberfläche
            window.location.href = this.configAssetLoader.getConfig().k5ExternalApps.adminUrl;
        }
    }

    /**
     * Erneutes ausführen der Login-Sequenz
     */
    retryLogin(): void {
        this.authService.runInitialLoginSequence();
    }

    /**
     * Logout
     */
    logout(): void {
        if (
            this.globalEditService.isEditing &&
            (this.globalEditService.getEditSectionForm() ? this.globalEditService.getEditSectionForm().dirty : true)
        ) {
            const dialogRef = this.dialog.open(UnsavedChangesDialogComponent);
            dialogRef.componentInstance.leaveSiteObservable.subscribe({
                next: (leaveSite: boolean) => {
                    if (leaveSite) {
                        this.globalEditService.switchToReadMode();
                        this.authService.logout();
                        this.signalRService.removeUserAndCloseConnection();
                    }
                }
            });
        } else {
            if (this.globalEditService.isEditing) {
                this.globalEditService.switchToReadMode();
            }
            this.authService.logout();
            this.signalRService.removeUserAndCloseConnection();
        }
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }

        this.signalRService.removeUserAndCloseConnection();
    }

    /**
     * Prüft ob der Benutzer authentifiziert und das Benutzerprofil
     * geladen ist.
     * @returns Ist der Benutzer authentifiziert und sind die Benutzerinformationen geladen
     */
    isAuthenticatedAndUserLoaded(): boolean {
        return this.userInformationService.hasUserInfo() && this.authService.isAuthenticated();
    }

    /**
     * Prüft ob der Pfad '/kontakte' oder '/waehlerverzeichnis' beinhaltet
     * Ja - DetailSearch wird geöffnet
     * Nein - DetailSearch wird geschlossen und der Suchparameter wird aus dem SuchParamService gelöscht
     */
    checkForSpecificCurrentUrl() {
        const currRoute = this.router.url;

        if (currRoute.includes(`${PathConstants.KONTAKTMANAGEMENT}/${PathConstants.KONTAKT_LISTE}`)) {
            this.specifyDetailSearch = PathConstants.KONTAKT_LISTE;
            if (
                (!this.rightSidenavigationService.rightSidenavOpen &&
                    (currRoute === RouterLinkConstants.KONTAKTE_LISTE ||
                        currRoute.includes(KontaktmanagementDetailsuche.SEARCH_KEY))) ||
                currRoute.includes(KontaktmanagementDetailsuche.GRUPPE_KEY)
            ) {
                this.rightSidenavigationService.openRightSidenav();
            } else if (
                (currRoute.includes(`${PathConstants.KONTAKT_LISTE}/${PathConstants.KONTAKT_UNTERNEHMEN}`) ||
                    currRoute.includes(`${PathConstants.KONTAKT_LISTE}/${PathConstants.KONTAKT_PERSON}`)) &&
                this.rightSidenavigationService.rightSidenavOpen
            ) {
                this.rightSidenavigationService.closeRightSidenav();
            }
        } else {
            this.rightSidenavigationService.closeRightSidenav();
            this.specifyDetailSearch = '';
        }
    }

    /**
     * Fragt nach, ob der Benutzer die Seite verlassen möchte, wenn er sich aktuell im Bearbeitungsmodus befindet
     */
    @HostListener('window:beforeunload', ['$event'])
    handleClose($event) {
        if (this.globalEditService.isEditing) {
            $event.returnValue = true;
        }
    }
}
