/**
 * Status der Datenmigration
 */
export enum DatenmigrationStatus {
    NotStarted = 0,
    Running = 10,
    Error = 20,
    Completed = 30
}

/**
 * Daten zur Anzeige von Informationen zur Datenmigration
 */
export interface DatenmigrationDto {
    mandantId: string;
    status: DatenmigrationStatus;
    meldungExtCount: number;
    personExtCount: number;
    errorMessage: string | null;
}
