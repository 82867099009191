// @ts-strict-ignore
import { Component, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { NotificationAlert } from '@core/models/notificationAlert';
import { NotificationConstants } from '@shared/constants/shared-constants';
import { NotificationService } from '@shared/services/notification.service';

@Component({
    selector: 'k5-notification-alert',
    templateUrl: './notification-alert.component.html'
})
export class NotificationAlertComponent {
    constructor(
        @Inject(MAT_SNACK_BAR_DATA) public notificationAlert: NotificationAlert,
        private readonly router: Router,
        private readonly notificationService: NotificationService,
        private readonly _snackRef: MatSnackBarRef<NotificationAlertComponent>
    ) {}

    /**
     * Gibt an, ob Details vorhanden sind
     */
    get detailsAvailable(): boolean {
        return !!this.notificationAlert.detailMessage;
    }

    /**
     * Navigiert zu einer Url oder öffnet einen neuen Tab.
     */
    handleNotificationAction(): void {
        this._snackRef.dismiss();
        if (this.notificationAlert.notification.type === NotificationConstants.TYP_K5NEXT_VERWEIS) {
            const url = this.notificationService.getVerweisUrlFromNotificationAlertDialog(
                this.notificationAlert.notification
            );
            this.router.navigateByUrl(url);
            this.notificationService
                .markNotificationAsRead(this.notificationAlert.notification.benachrichtigungId)
                .subscribe({
                    next: () => {
                        if (this.notificationService.numberOfUngeleseneNotifications() > 0) {
                            this.notificationService.numberOfUngeleseneNotifications.set(
                                this.notificationService.numberOfUngeleseneNotifications() - 1
                            );
                        }
                    }
                });
        } else if (this.notificationAlert.notification.type === NotificationConstants.TYP_LINK) {
            window.open(this.notificationAlert.notification.referenz?.deepLinkUrl, '_blank').focus();
        }
    }
}
