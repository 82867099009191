<k5next-dialog-heading [heading]="title" [showClose]="false"></k5next-dialog-heading>

<k5next-dialog-content>
    <!-- file input -->
    <form class="flex items-center py-2" enctype="multipart/form-data">
        <input
            #fileInput
            hidden="true"
            type="file"
            [accept]="getAcceptTypes()"
            (change)="onFileSelected(fileInput.files)"
        />
        <button type="button" mat-raised-button data-cy="choose-file-btn" color="primary" (click)="fileInput.click()">
            Datei auswählen (<ng-container *ngFor="let fileFormat of fileFormats">{{
                fileFormat.endings.join()
            }}</ng-container
            >)
        </button>
        <span class="pl-4">{{ filename }}</span>
    </form>

    <!-- upload progress bar -->
    <section class="pt-4" *ngIf="loading">
        <mat-progress-bar mode="determinate" [value]="progress">Datei hochladen</mat-progress-bar>
    </section>
</k5next-dialog-content>

<k5next-dialog-actions [alignEnd]="true">
    <button mat-stroked-button mat-dialog-close color="primary" type="button">Abbrechen</button>
    <button
        mat-raised-button
        color="primary"
        type="button"
        data-cy="upload-file-btn"
        (click)="uploadFile()"
        [disabled]="!isFileSelected || loading"
    >
        Datei hochladen
    </button>
</k5next-dialog-actions>
