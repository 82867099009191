// @ts-strict-ignore
import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { UiConstants } from '@core/constants/ui-constants';
import { DateUtilsConstants } from '@shared/constants/shared-constants';
import { DateTime } from 'luxon';

/**
 * Klasse für die Bearbeitung von Date-Objekten
 */
@Injectable({
    providedIn: 'root'
})
export class DateUtilsService {
    private static readonly timeFormatString = DateUtilsConstants.TIME_FORMAT_STRING;

    /**
     * Liefert das übergebene Date-Objekt als formatierten String zurück.
     * @param date Date-Objekt mit Datum und Uhrzeit
     * @param includeTime Standard = true mit Uhrzeit; false ohne Uhrzeit
     * @returns string Formatierter String des Datums und der Uhrzeit
     */
    static convertDateToFormattedDateString(date: Date, format?: string): string {
        if (date) {
            return format
                ? formatDate(date, format, UiConstants.LOCALE_AT)
                : formatDate(date, 'mediumDate', UiConstants.LOCALE_AT);
        }
        return '';
    }

    /**
     * Konvertiert das Luxon-Datumsobjekt in die Date-Repräsentation in UTC am gleichen Tag
     * @param date Luxon-Date-Objekt
     * @returns Datum ohne Zeit
     */
    static convertLuxonDateTimeToDate(date: DateTime | Date): Date {
        if (!date) {
            return null;
        }
        if (!(date instanceof DateTime)) {
            return date;
        }
        return new Date(date.toISODate());
    }

    /**
     * Liefert die Uhrzeit des übergebenen Date-Objekt als formatierten String zurück.
     * @param date Date-Objekt mit Datum und Uhrzeit
     * @returns string Formatierter String der Uhrzeit
     */
    static convertDateToFormattedTimeString(date: Date): string {
        return formatDate(date, this.timeFormatString, UiConstants.LOCALE_AT);
    }

    /**
     * Prüft, ob 'bis Zeit' nach 'vor Zeit' ist
     * @param timeFrom
     * @param timeUntil
     * @returns ob Zeiten gütlig sind
     */
    static compareTimeStrings(timeFrom: string, timeUntil: string): boolean {
        const timeFromArray = timeFrom.split(':').map(Number);
        const timeUntilArray = timeUntil.split(':').map(Number);
        if (timeFromArray[0] < timeUntilArray[0]) {
            return true;
        } else if (timeFromArray[0] === timeUntilArray[0]) {
            if (timeFromArray[1] < timeUntilArray[1]) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }
}
