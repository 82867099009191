import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AlertService } from '@core/services/alert.service';
import { FileFormat, FileFormats } from '../file-format';

@Component({
    selector: 'k5-drag-and-drop-files',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './drag-and-drop-files.component.html',
    styleUrls: ['./drag-and-drop-files.component.scss']
})
export class DragAndDropFilesComponent {
    @Input()
    text: string = 'Datei hochladen';

    @Input()
    supportedFileFormats: FileFormat[] = FileFormats.PREDEFINED_FILE_TYPES;

    @Input()
    description: string = 'Dateien können mit Drag and Drop hochgeladen werden.';

    @Input()
    multiple: boolean = false;

    files: File[] = [];

    @Output()
    fileSelectionChanged: EventEmitter<File[]> = new EventEmitter<File[]>();

    constructor(
        private readonly alertService: AlertService,
        public dialog: MatDialog
    ) {}

    /**
     * Baut den accept-String für die erlaubten Typen zusammen
     */
    getAcceptTypes(): string {
        if (!this.supportedFileFormats) {
            return '';
        }
        return this.supportedFileFormats.map((x) => x.mimeType).join(',');
    }

    /**
     * Behandelt die zu uploadenden Dateien nach dem im Datei-Browser Dateien ausgewählt wurden.
     * @param files Dateien zum Uploaden
     */
    fileBrowseHandler(files: FileList): void {
        this.addFiles(Array.from(files));
    }

    /**
     * Setzt den Wert des Input-Elements zurück, sodass die gleiche Datei
     * ein weiteres mal ausgewählt werden kann.
     */
    inputClicked(event: Event): void {
        (event.target as HTMLInputElement).value = '';
    }

    /**
     * Behandelt die durch Drag-and-Drop hinzugefügten Dateien.
     * @param files Dateien zum Uploaden
     */
    onFileDropped(files: FileList): void {
        this.addFiles(Array.from(files));
    }

    /**
     * Fügt die Dateien zur Liste hinzu
     * @param files Dateien
     */
    addFiles(files: File[]): void {
        if (!files) {
            return;
        }

        if (!this.multiple && files.length > 1) {
            this.alertService.warning('Es kann nur eine Datei hochgeladen werden!');
            return;
        } else if (!this.multiple && files.length === 1 && this.files.length > 0) {
            // Datei wird ersetzt
            if (!this.isFileTypeAllowed(files[0].type)) {
                this.alertService.error(`Der Dateityp ${files[0].type} wird nicht unterstützt!`);
                return;
            }
            this.files[0] = files[0];
            this.fileSelectionChanged.emit(this.files);
            return;
        }

        for (const file of files) {
            if (!this.isFileTypeAllowed(file.type)) {
                this.alertService.error(`Der Dateityp ${file.type} wird nicht unterstützt!`);
            } else {
                this.files.push(file);
            }
        }
        this.fileSelectionChanged.emit(this.files);
    }

    /**
     * Gibt zurück, ob der Dateityp erlaubt ist
     * @param type MimeType einer Datei
     */
    isFileTypeAllowed(type: string): boolean {
        return this.supportedFileFormats.findIndex((x) => x.mimeType === type) >= 0;
    }

    /**
     * Löscht die Datei von der Liste
     * @param index Index der Datei im Array
     */
    removeFile(index: number) {
        if (this.files && this.files.length > 0 && this.files.length > index) {
            this.files.splice(index, 1);
            this.fileSelectionChanged.emit(this.files);
        }
    }
}
