import { HttpClient, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigAssetLoaderService } from '@core/services/config-asset-loader.service';
import { Observable } from 'rxjs';
import { DatenmigrationDto } from '../models/datenmigrationDto';

@Injectable({
    providedIn: 'root'
})
export class DatenmigrationService {
    private readonly baseUrl: string;

    constructor(
        private readonly httpClient: HttpClient,
        private readonly configService: ConfigAssetLoaderService
    ) {
        this.baseUrl = this.configService.getConfig().k5Meldewesen.meldewesenUiApiUrl;
    }

    /**
     * Gibt den Status der Datenmigration für den Mandanten zurück.
     * @param mandantId Id des Mandanten
     * @returns DatenmigrationDto
     */
    getDatenmigrationStatus(mandantId: string): Observable<DatenmigrationDto> {
        return this.httpClient.get<DatenmigrationDto>(`${this.baseUrl}/v1/mandanten/${mandantId}/datenmigration`);
    }

    /**
     * Führt den LMR-Import der Daten für den Mandanten durch.
     * @param mandantId Id des Mandanten
     * @param formdata FormData mit den Daten für die Migration
     * @returns DatenmigrationDto
     */
    importLmrDaten(mandantId: string, formdata: FormData): Observable<HttpEvent<DatenmigrationDto>> {
        return this.httpClient.post<DatenmigrationDto>(
            `${this.baseUrl}/v1/mandanten/${mandantId}/lmr-import`,
            formdata,
            {
                reportProgress: true,
                observe: 'events'
            }
        );
    }
}
