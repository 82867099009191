// @ts-strict-ignore
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { RouterLinkConstants } from '@core/constants/url-constants';
import { AlertService } from '@core/services/alert.service';
import { AppConfig, ConfigAssetLoaderService } from '@core/services/config-asset-loader.service';
import {
    K5NextNotification,
    K5NextNotificationBenutzerUrlKey,
    K5NextNotificationResponse
} from '@k5next/ui-components';
import { KontaktmanagementDetailsuche, NotificationConstants } from '@shared/constants/shared-constants';
import { UIBenachrichtigung } from '@shared/models/uIBenachrichtigung';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {
    numberOfUngeleseneNotifications = signal(0);
    benutzerUrlKey = K5NextNotificationBenutzerUrlKey.FachlicheBenutzerUrlKey;
    config: AppConfig = null;
    isInitialLoadingDone = signal(false);
    notificationSubject: BehaviorSubject<UIBenachrichtigung[]> = new BehaviorSubject([]);
    notification$: Observable<UIBenachrichtigung[]> = this.notificationSubject.asObservable();

    notificationHistorySubject: BehaviorSubject<UIBenachrichtigung[]> = new BehaviorSubject([]);
    notificationHistory$: Observable<UIBenachrichtigung[]> = this.notificationHistorySubject.asObservable();

    constructor(
        private readonly httpClient: HttpClient,
        private readonly configService: ConfigAssetLoaderService,
        private readonly alertService: AlertService
    ) {
        this.config = this.configService.getConfig();
    }

    /**
     * Setzt die Anzahl der Benachrichtigungen
     * @param amount Anzahl der Benachrichtigungen
     */
    setNotificationAmount(amount: number): void {
        this.numberOfUngeleseneNotifications.set(amount);
    }

    /**
     * Aufarbeitet die K5NextNotificationResponse.
     * Stellt fest wie viele ungelesene Benachrichtigungen es gibt und handelt die Fälle,
     * wenn noch nicht empfangene Benachrichtigungen kommen.
     * Die noch nicht empfangene werden für den Benutzer mit dem Alertservice dargestellt.
     * @param response data als K5NextNotificationResponse.
     */
    setNewNotificationInfo(response: K5NextNotificationResponse): void {
        // Es gibt nur eine ungelesene Notification und diese ist nocht nicht empfangen worden
        if (response.anzahlUngelesene === 1 && response.anzahlUngelesene !== this.numberOfUngeleseneNotifications()) {
            // Die eine ungelesene Notification laden
            const ungeleseneNotification = response.benachrichtigungen.find(
                (notification: K5NextNotification) => !notification.wurdeGelesen
            );
            // gefunden
            if (ungeleseneNotification) {
                // Benachrichtigung über die neue Notification anzeigen
                this.alertService.notification(ungeleseneNotification.text, ungeleseneNotification);
            }
            this.setNotificationAmount(response.anzahlUngelesene);
        }
        // Es gibt mehrere ungelesene Notifications.
        // Muss bestimmen wie viel noch nicht empfangen wurden.
        else if (response.anzahlUngelesene > 1) {
            if (this.numberOfUngeleseneNotifications() !== response.anzahlUngelesene) {
                const numberOfNewNotification = response.anzahlUngelesene - this.numberOfUngeleseneNotifications();
                if (numberOfNewNotification > 0) {
                    this.alertService.info('Sie haben ' + numberOfNewNotification + ' neue Benachrichtigungen');
                }
            }
            this.setNotificationAmount(response.anzahlUngelesene);
        }
    }

    /**
     * Gibt alle aktuellen Benachrichtigungen zurück.
     * @param includeHistory Gibt an, ob Benachrichtigungen inklusive der Historie zurück gegeben werden sollen.
     * @returns ein Array von Benachrichtigungen als Observable.
     */
    getNotifications(includeHistory: boolean): Observable<K5NextNotificationResponse> {
        const params: HttpParams = new HttpParams().set('historie', includeHistory);
        return this.httpClient.get<K5NextNotificationResponse>(
            `${this.configService.getConfig().k5Notification.notificationServiceUiApiBaseUrl}/${this.benutzerUrlKey}`,
            { params: params }
        );
    }

    /**
     * Markiert eine Benachrichtigung als gelesen
     * @param notificationId der Notification.
     * @returns ein Array von Benachrichtigungen als Observable.
     */
    markNotificationAsRead(notificationId: string): Observable<K5NextNotificationResponse> {
        return this.httpClient.put<K5NextNotificationResponse>(
            `${this.configService.getConfig().k5Notification.notificationServiceUiApiBaseUrl}/${this.benutzerUrlKey}/${notificationId}/gelesen`,
            null
        );
    }

    /**
     * Ermittelt die URL des Verweis, nachdem auf die Benachrichtigung geklickt wurde.
     * @param notification Notification Object.
     * @returns die Url der Notification als String.
     */
    getVerweisUrlNeu(notification: K5NextNotification): string | null {
        let url: string;
        if (notification.referenz) {
            switch (notification.referenz.objektTyp) {
                case NotificationConstants.TYP_GESPEICHERTE_SUCHE:
                    url = `${RouterLinkConstants.KONTAKTE_LISTE}?${KontaktmanagementDetailsuche.SEARCH_KEY}=${notification.referenz.objektId}`;
                    break;
                case NotificationConstants.TYP_SUCHERGEBNIS:
                    url = `${RouterLinkConstants.KONTAKTE_LISTE}?${KontaktmanagementDetailsuche.SEARCH_RESULT_KEY}=${notification.referenz.objektId}`;
                    break;
                case NotificationConstants.TYP_GRUPPE:
                    url = `${RouterLinkConstants.KONTAKTE_LISTE}?${KontaktmanagementDetailsuche.GRUPPE_KEY}=${notification.referenz.objektId}`;
                    break;
                default:
                    break;
            }
        }
        return url;
    }

    /**
     * Ermittelt die URL des Verweis, nachdem auf die Benachrichtigung geklickt wurde.
     * @param notification Notification Object.
     * @returns die Url der Notification als String.
     */
    getVerweisUrlFromNotificationAlertDialog(notification: K5NextNotification): string | null {
        let url: string;
        if (notification.referenz) {
            switch (notification.referenz.objektTyp) {
                case NotificationConstants.TYP_GESPEICHERTE_SUCHE:
                    return `${RouterLinkConstants.KONTAKTE_LISTE}?${KontaktmanagementDetailsuche.SEARCH_KEY}=${notification.referenz.objektId}`;
                case NotificationConstants.TYP_SUCHERGEBNIS:
                    return `${RouterLinkConstants.KONTAKTE_LISTE}?${KontaktmanagementDetailsuche.SEARCH_RESULT_KEY}=${notification.referenz.objektId}`;
                case NotificationConstants.TYP_GRUPPE:
                    return `${RouterLinkConstants.KONTAKTE_LISTE}?${KontaktmanagementDetailsuche.GRUPPE_KEY}=${notification.referenz.objektId}`;
                default:
                    break;
            }
        }
        return url;
    }
}
