/**
 * Interface für einen Dateityp
 */
export interface FileFormat {
    description: string;
    mimeType: string;
    endings: string[];
}

/**
 * Vordefinierte Dateitypen
 */
export class FileFormats {
    static readonly PDF_FILE_FORMAT: FileFormat = {
        description: 'PDF-Datei',
        mimeType: 'application/pdf',
        endings: ['.pdf']
    };
    static readonly ZIP_FILE_FORMAT: FileFormat = {
        description: 'ZIP-Datei',
        mimeType: 'application/zip',
        endings: ['.zip']
    };
    static readonly X_ZIP_COMPRESSED_FILE_FORMAT: FileFormat = {
        description: 'ZIP-Datei',
        mimeType: 'application/x-zip-compressed',
        endings: ['.zip']
    };
    static readonly CSV_FILE_FORMAT: FileFormat = { description: 'CSV-Datei', mimeType: 'text/csv', endings: ['.csv'] };
    static readonly JPG_FILE_FORMAT: FileFormat = {
        description: 'JPG-Datei',
        mimeType: 'image/jpeg',
        endings: ['.jpeg', '.jpg', '.jfif']
    };
    static readonly PNG_FILE_FORMAT: FileFormat = {
        description: 'PNG-Datei',
        mimeType: 'image/png',
        endings: ['.png']
    };
    static readonly SVG_FILE_FORMAT: FileFormat = {
        description: 'SVG-Datei',
        mimeType: 'image/svg+xml',
        endings: ['.svg']
    };
    static readonly XML_FILE_FORMAT: FileFormat = {
        description: 'XML-Datei',
        mimeType: 'application/xml',
        endings: ['.xml']
    };

    static readonly PREDEFINED_FILE_TYPES: FileFormat[] = [
        this.PDF_FILE_FORMAT,
        this.ZIP_FILE_FORMAT,
        this.CSV_FILE_FORMAT,
        this.JPG_FILE_FORMAT,
        this.PNG_FILE_FORMAT,
        this.SVG_FILE_FORMAT,
        this.XML_FILE_FORMAT
    ];
}
