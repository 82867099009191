// @ts-strict-ignore
import { EventEmitter, Directive, HostBinding, HostListener, Output } from '@angular/core';

@Directive({
    selector: '[k5DragAndDrop]'
})
export class DragAndDropFilesDirective {
    @HostBinding('class.fileover')
    fileOver: boolean = false;

    @Output()
    fileDropped = new EventEmitter<FileList>();

    /**
     * Horcht auf Dragover-Events und setzt den FileOver-Zustand.
     * @param event Dragover-Event
     */
    @HostListener('dragover', ['$event'])
    onDragOver(event: DragEvent): void {
        event.preventDefault();
        event.stopPropagation();
        this.fileOver = true;
    }

    /**
     * Horcht auf Dragleave-Events und setzt den FileOver-Zustand zurück.
     * @param event Dragleave-Event
     */
    @HostListener('dragleave', ['$event'])
    onDragLeave(event: DragEvent): void {
        event.preventDefault();
        event.stopPropagation();
        this.fileOver = false;
    }

    /**
     * Horcht auf Drop-Events, triggert ein Event mit den gedroppten Files
     * und setzt den FileOver-Zustand zurück.
     * @param event Drop-Event
     */
    @HostListener('drop', ['$event'])
    onDrop(event: DragEvent): void {
        event.preventDefault();
        event.stopPropagation();

        this.fileOver = false;
        const files = event.dataTransfer?.files;

        if (files?.length > 0) {
            this.fileDropped.emit(files);
        }
    }
}
