<div class="px-4">
    <header>
        <k5-responsive-title>
            <k5-page-header header="Meldewesen - Konfiguration" icon="fa-duotone fa-sliders-h fg-primary">
            </k5-page-header>
        </k5-responsive-title>
    </header>
    <main>
        <k5-section-header [title]="'Datenmigration'" icon="fa-upload"></k5-section-header>

        @if (loading()) {
            <div class="py-4">
                <k5-loading message="Datenmigrationsstatus wird geladen ..."></k5-loading>
            </div>
        }

        @if (datenmigration() && !loading()) {
            @switch (datenmigration().status) {
                @case (DATENMIGRATION_STATUS.NotStarted) {
                    <div class="p-4">
                        <p class="font-bold">Keine Daten vorhanden</p>
                        <p class="pb-2">Beginnen Sie mit der Datenmigration indem Sie eine Datei hochladen</p>
                        <button mat-raised-button color="primary" type="button" (click)="openFileUploadDialog()">
                            Datei hochladen
                        </button>
                    </div>
                }
                @case (DATENMIGRATION_STATUS.Completed) {
                    <div class="p-4">
                        <p class="font-bold">Import der LMR-Daten abgeschlossen</p>
                        <p>Anzahl der verarbeiteten Meldungssätze: {{ datenmigration().meldungExtCount }}</p>
                        <p class="pb-2">Anzahl der verarbeiteten Personensätze: {{ datenmigration().personExtCount }}</p>
                        <button mat-raised-button color="primary" type="button" (click)="openFileUploadDialog()">
                            Neue Datei hochladen
                        </button>
                    </div>
                }
            }
        }
    </main>
</div>
